<template>
  <div>
    <el-dialog
      title="费用明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="工种" prop="employeeTypeId">
              <type-cascader
                @input="getPrice"
                v-model="form.employeeTypeId"
                info="EmployeeType"
                :canAdd="true"
                auth="EmployeeType"
                @addClick="()=>{this.employeeTypeItem = {}}"
              ></type-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工时费" prop="price">
              <el-popover ref="price" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.price|money}}</span>
              </el-popover>
              <el-input
                v-popover:price
                type="number"
                v-model="form.price"
                placeholder="工时费"
                clearable
                @input="setAmount"
                @mousewheel.native.prevent
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input
                type="number"
                v-model="form.quantity"
                placeholder="数量"
                clearable
                @input="setAmount"
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount">
              <el-popover ref="amount" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.amount|money}}</span>
              </el-popover>
              <el-input
                v-popover:amount
                type="number"
                v-model="form.amount"
                placeholder="金额"
                clearable
                @change="setPriceOrQuantity"
                @mousewheel.native.prevent
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)">保存关闭</el-button>
            <el-button type="success" @click="save(true)">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <add-type
        typeKind="EmployeeType"
        :item="employeeTypeItem"
        @success="id=>{this.form.employeeTypeId=id;this.getPrice(id)}"
      ></add-type>
    </div>
  </div>
</template>

<script>
import guid from "@/common/guid.js";
import setName from "@/common/setName";
import TypeCascader from "@/components/TypeCascader";
import AddType from "@/components/BaseType/TypeItem";
export default {
  props: ["item"],
  components: {
    TypeCascader,
    AddType
  },
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        employeeTypeId: [this.$g.required],
        price: [this.$g.moreThanZero],
        quantity: [this.$g.moreThanZero]
      },
      employeeTypeItem: {}
    };
  },
  watch: {
    item: {
      handler: function(row) {
        if (row.id) {
          this.form = { ...row };
        } else {
          this.initForm();
        }
        this.visible = true;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      },
      deep: true
    }
  },
  methods: {
    initForm() {
      this.form = {
        id: guid(),
        employeeTypeId: "",
        employeeTypeName: "",
        price: null,
        quantity: null,
        amount: null,
        remark: ""
      };
    },
    getPrice(employeeTypeId) {
      if (employeeTypeId) {
        this.$get("Labor/GetEmployeeTypePrice", {
          employeeTypeId
        }).then(r => {
          this.form.price = r;
          this.setAmount();
        });
      } else {
        this.form.price = null;
        this.setAmount();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        setName(
          "EmployeeType",
          this.form,
          "employeeTypeId",
          "employeeTypeName"
        );
        this.$emit("success", { ...this.form });

        if (isContinue) {
          this.initForm();
        } else {
          this.visible = false;
        }
      });
    },
    setAmount() {
      let quantity = Number(this.form.quantity);
      let price = Number(this.form.price);
      this.form.amount = this.amountFormat(quantity * price);
    },
    setPriceOrQuantity() {
      let amount = Number(this.form.amount);
      let quantity = Number(this.form.quantity);
      let price = Number(this.form.price);
      if (quantity != 0) {
        this.form.price = amount / quantity;
        return;
      }
      if (price != 0) {
        this.form.quantity = amount / price;
        return;
      }
      this.form.amount = 0;
    }
  }
};
</script>

<style>
</style>