<template>
  <div v-show="show" class="pay-table">
    <el-table
      :data="payList"
      stripe
      border
      @row-dblclick="payBill"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column label="付款日期" prop="billDate" width="90">
        <template slot-scope="scope">{{
          scope.row.billDate | datetime
        }}</template>
      </el-table-column>
      <el-table-column
        label="付款编号"
        prop="billCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="结算金额"
        prop="balanceAmount"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.balanceAmount | thousands
        }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["billId"],
  data() {
    return {
      payList: [],
    };
  },
  computed: {
    show() {
      return this.payList.length > 0 && this.billId;
    },
  },
  watch: {
    billId: function () {
      this.getList();
    },
  },
  activated() {
    this.getList();
  },
  methods: {
    getList() {
      this.payList = [];
      if (this.billId) {
        this.$get("Payment/GetPayListByBillId", {
          billId: this.billId,
        }).then((r) => {
          this.payList = r;
        });
      }
    },
    payBill(row) {
      if (this.hasPermission("Payment")) {
        this.$router.push({
          name: "PaymentItem",
          query: { id: row.id },
        });
      }
    },
    getSummaries(param) {
      let { columns, data } = param;
      let total = 0;
      data.forEach((d) => {
        total += d.balanceAmount;
      });

      let sums = ["付款合计", "", this.thousandsFormat(total)];

      return sums;
    },
  },
};
</script>

<style>
.pay-table {
  position: absolute;
  top: 0;
  right: 5px;
  width: 310px;
}
</style>