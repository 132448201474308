<template>
  <div>
    <strong>操作员：{{operator}}</strong>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  computed: {
    operator() {
      let res = "";
      if (this.item.operatorId) {
        let op = this.$store.state.baseinfo.Operator.find(
          (r) => r.id == this.item.operatorId
        );
        if (op) {
          res = op.name;
        }
      }

      return res || this.$store.state.userInfo.operatorName;
    },
  },
};
</script>

<style>
</style>