<template>
  <div>
    <el-dialog
      :title="item.id?'编辑员工':'新增员工'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号" prop="employeeCode">
              <el-input v-model="form.employeeCode" placeholder="员工编号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="employeeName">
              <el-input v-model="form.employeeName" placeholder="员工姓名" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电话">
              <el-input v-model="form.tel" placeholder="联系电话" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态">
              <el-switch
                v-model="form.isStop"
                active-color="red"
                inactive-color="green"
                active-text="停用"
                inactive-text="正常"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" type="textarea" :rows="2" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)" :loading="loading">保存关闭</el-button>
            <el-button type="success" @click="save(true)" :loading="loading" v-if="!item.id">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        employeeCode: [{ required: true, message: "编号不能为空" }],
        employeeName: [{ required: true, message: "姓名不能为空" }]
      }
    };
  },
  watch: {
    item: {
      handler: function(value) {
        if (value.id) {
          this.loading = true;
          this.$get("Employee/Detail", {
            id: value.id
          })
            .then(r => {
              this.form = r;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.reset();
        }
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = {
        employeeCode: "",
        employeeName: "",
        tel: "",
        remark: "",
        isStop: false
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }

        this.loading = true;
        let values = this.form;

        if (values.id) {
          this.$post("Employee/Edit", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.visible = false;
              this.$store.commit("updateBaseInfo", {
                info: "Employee",
                item: r
              });
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Employee/Add", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.$store.commit("addBaseInfo", {
                info: "Employee",
                item: r
              });
              if (isContinue) {
                this.reset();
              } else {
                this.visible = false;
              }
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style>
</style>