import http from '@/ajax.js'
import {
    MessageBox
} from 'element-ui';
/**
 * 生成付款单
 * @param {*} billDate 付款单日期
 * @param {*} supplierId 供应商Id
 * @param {*} bankNo 银行账号
 * @param {*} billType 0期初1采购2项目费用3人工费用
 * @param {*} billId 要付款的单据Id
 * @param {*} amount 单据金额
 * @param {*} callback 成功后的回调函数
 */
const addPay = function (billDate, supplierId, bankNo, billType, billId, amount, callback) {
    MessageBox.prompt('当前单据结算金额为【' + amount + '】，请确认付款金额', '付款', {
        showClose: false,
        closeOnClickModal:false,
        closeOnPressEscape:false,
        
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: amount,
        inputPattern: /^(\-)?\d+(\.\d{1,2})?$/,
        inputErrorMessage: '付款金额不正确'
    }).then(({
        value
    }) => {
        let payAmount = value
        //生成单号
        http.get("Sys/GetBillCode", {
            billType: 6,
            billDate: billDate
        }).then(billCode => {
            let bill = {
                billCode: billCode,
                billDate: billDate,
                billType: 2,
                supplierId: supplierId,
                remark: "",
                bankNo: bankNo
            };
            let detail = [{
                billType: billType,
                billId: billId,
                remainAmount: amount,
                payAmount: payAmount,
                balanceAmount: amount,
                remark: "",
                chargeAmount: 0
            }];
            let charge = [];
            http.post("Payment/Add", {
                    bill,
                    detail,
                    charge
                })
                .then(r => {

                    if (callback) {
                        callback()
                    }
                })
        })
    }).catch(() => {
        if (callback) {
            callback()
        }
    });

}

export default addPay